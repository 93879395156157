<template>
  <div id="no-hidden-dialog" class="ql-snow no-hidden-dialog">
      <div id="document-wrap" class="document-wrap">
        <el-img-viewer
          v-if="isViewerVisible"
          class="viewer"
          :url-list="nowImgsrc"
          :on-close="closeImgViewer"
        ></el-img-viewer>
        <div id="document-content" @click="showImage">
          <div
            class="top-label"
            :class="{ hidden: this.selectedIndexes.length != 2 }"
          >
            <ins
              style="
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 2px;
              "
            >
              {{ $t("knowledge.AddedContent") }}</ins
            >
            <span style="visibility: hidden">--</span>
            <del
              style="
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 2px;
              "
            >
              {{ $t("knowledge.DeletedContent") }}</del
            >
          </div>
          <div v-html="dividedHtml" id="show-tiptap-docunent-zdh"></div>
        </div>
        <div id="hide-wrap" class="hide-wrap"></div>
      </div>
  </div>
</template>

<script>
import { getContent } from "@/network/knowledge";
import Quill from "quill";
import { distribution, sendComments } from "@/network/knowledge";
import QuillBetterTable from "@/plugins/quill-better-table.js";
import DOMPurify from "dompurify";
import hljs from "highlight.js";
import HtmlDiff from "htmldiff-js";
export default {
  components: {
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    rightCommentList: {
      type: Array,
      default() {
        return [];
      },
    },
    documentId: {
      type: String,
      default: "",
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    docHistories: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedIndexes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      quill: null,
      quill2: null,
      // 目前选中的行id
      lineId: "",
      data: "",
      data1: "",
      data2: "",
      outerWidth: 0,
      nowImgsrc: [],
      atShow: false,
      atShowposition: {
        left: 0,
        top: 0,
      },
      isViewerVisible: false,
      cache: {},
    };
  },
  computed: {
    dividedHtml() {
      // const dividedHtml = DOMPurify.sanitize(this.data);
      const dividedHtml = this.data;
      return dividedHtml;
    },
  },
  watch: {
    $route(val) {
      if (val.hash) {
        this.locationElement(val.hash);
      }
    },
    selectedIndex(newVal, oldVal) {
      if (this.docHistories && this.docHistories.length > 0) {
        this.gethtml(this.documentId);
      }
    },
    docHistories(newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        this.gethtml(this.documentId);
      }
    },
    selectedIndexes(newVal, oldVal) {
      this.gethtml(this.documentId);
    },
  },
  mounted() {
    this.initQuill();
    // 监听dom变化
    this.listenerwidth();
    console.log(this.docHistories);
    
    // this.gethtml(this.checkedNode ? this.checkedNode.documentId : "");
    if (this.docHistories && this.docHistories.length > 0) {
      this.gethtml(this.documentId);
    }
  },
  methods: {
    showImage(event) {
      if (event.target.localName === "img") {
        this.nowImgsrc = [event.target.currentSrc];
        this.isViewerVisible = true;
      }
      if (event.target.id.split("#")[0] === "link") {
        let l = event.target.attributes.link.value;
        console.log();

        window.open(
          (l.slice(0, 4) == "http" ? "" : "//") +
            event.target.attributes.link.value,
          "_blank"
        );
      }
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    // 监听宽度变化
    listenerwidth() {
      const self = this;
      this.observer = new ResizeObserver((entries) => {
        entries.forEach((item) => {
          self.outerWidth = item.borderBoxSize[0].inlineSize;
        });
      });
      let content = document.getElementById("document-content");
      this.observer.observe(content);
    },
    initQuill() {
      this.quill = new Quill("#hide-wrap", {
        modules: {
          toolbar: false,
          history: {
            delay: 2000,
            maxStack: 500,
          },
          ImageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
          table: false, // disable table module
          "better-table": {
            operationMenu: {
              items: {
                insertColumnRight: {
                  text: this.$t("knowledge.InsertRight"),
                },
                insertColumnLeft: {
                  text: this.$t("knowledge.InsertLeft"),
                },
                insertRowUp: {
                  text: this.$t("knowledge.InsertAbove"),
                },
                insertRowDown: {
                  text: this.$t("knowledge.InsertBelow"),
                },
                mergeCells: {
                  text: this.$t("knowledge.MergeCells"),
                },
                unmergeCells: {
                  text: this.$t("knowledge.SplitCells"),
                },
                deleteColumn: {
                  text: this.$t("knowledge.DeleteColumn"),
                },
                deleteRow: {
                  text: this.$t("knowledge.DeleteRow"),
                },
                deleteTable: {
                  text: this.$t("knowledge.DeleteTable"),
                },
              },
            },
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings,
          },
        },
        theme: "snow",
      });
    },
    getHtmlFromVersionAsync(version) {
      // return version.contentHtml
      return new Promise((resolve) => {
        // const delta = JSON.parse(version.content);
        // this.quill.setContents(delta, "api");
        // resolve(this.quill.root.innerHTML);
        resolve(version.contentHtml)
      });
    },
    getDiffHtml(content1, content2) {
      return HtmlDiff.execute(content1, content2);
    },

    gethtml(id) {
      const docContent = this.docHistories[this.selectedIndex];

      if (this.selectedIndexes.length === 2) {
        const docContent1 = this.docHistories[this.selectedIndexes[0]];
        const docContent2 = this.docHistories[this.selectedIndexes[1]];

        // Check cache before requesting
        let contentPromise1 = this.cache[docContent1.versionNumber]
          ? Promise.resolve(this.cache[docContent1.versionNumber])
          : this.getHtmlFromVersionAsync(docContent1);

        let contentPromise2 = this.cache[docContent2.versionNumber]
          ? Promise.resolve(this.cache[docContent2.versionNumber])
          : this.getHtmlFromVersionAsync(docContent2);

        Promise.all([contentPromise1, contentPromise2]).then(
          ([content1, content2]) => {
            // Store in cache if not present
            if (!this.cache[docContent1.versionNumber]) {
              this.cache[docContent1.versionNumber] = content1;
            }

            if (!this.cache[docContent2.versionNumber]) {
              this.cache[docContent2.versionNumber] = content2;
            }

            this.data =
              docContent1.versionNumber > docContent2.versionNumber
                ? this.getDiffHtml(content2, content1)
                : this.getDiffHtml(content1, content2);
          }
        );
      } else if (docContent.content) {
        // Check cache before requesting
        if (this.cache[docContent.versionNumber]) {
          this.data = this.cache[docContent.versionNumber];
        } else {
          this.getHtmlFromVersionAsync(docContent).then((content) => {
            // Store in cache
            this.cache[docContent.versionNumber] = content;
            this.data = content;
          });
        }
      } else {
        this.data = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
#document-content {
  min-height: 40vh;
}
.at_position {
  position: absolute;
}
#toolbar-container2 {
  display: none;
}
#editor-container2 {
  width: 100%;
  border-top: 1px solid #ccc;
}
.ql-snow,
.ql-edit {
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  .ql-editor {
    height: fit-content;
    padding-right: 0px;
  }
}
::v-deep .my-alink,
::v-deep .my-atitle,
::v-deep .my-adesc,
::v-deep .my-acard_link {
  cursor: pointer;
}
.document-wrap {
  width: 100%;
  padding-left: 84px;
  padding-right: 15px;
  padding-bottom: 40px;
  text-align: left;
  overflow-x: visible;
  position: relative;
  overflow-y: visible;
  .hide-wrap {
    display: none;
  }
  .toolbar-container {
    display: none;
  }
}
#menu {
  display: none;
  position: absolute;
  width: 130px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  div {
    div {
      height: 40px;
      line-height: 40px;
      color: rgba(48, 100, 143, 1);
      font-size: 12px;
      text-align: center;
      cursor: default;
      &:hover {
        background: rgba(229, 229, 229, 0.61);
        cursor: pointer;
      }
    }
  }
}
.top-label {
  margin-left: auto;
  text-align: right;
  font-size: 18px;
}
</style>
<style lang="scss">
#show-tiptap-docunent-zdh {
  font-size: 15px;
  text-align: left;
  color: rgb(23, 23, 23);
  font-family: 'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

  .mention {
    font-size: 13px;
    background-color: #a9d5ff;
    border-radius: 4px;
    box-decoration-break: clone;
    color: #1873cc;
    padding: 1px 5px;
  }
  img {
    // display: block;
    height: auto;
    // margin: 1.5em 0;
    max-width: 100%;
    width: 500px;

    &.ProseMirror-selectednode {
      outline: 3px solid #0064ff;
    }
  }
  ul[data-type="taskList"] {
    list-style: none;
    margin-left: 0;
    padding: 0;
    p {
      margin: 5px 0;
    }
    li {
      align-items: flex-start;
      display: flex;
      align-items: center;

      > label {
        margin-right: 0.5em;
        user-select: none;
        width: 20px;
        input {
          // background: red;
        }
      }

      > div {
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
    }

    ul[data-type="taskList"] {
      margin: 0;
    }
  }
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -0.5px;
    line-height: normal;
    padding: 5px 3px;
    position: absolute;
    top: -2em;
    user-select: none;
    white-space: nowrap;
  }
  ul {
    padding-left: 25px;
  }
  ul li::marker {
    color: #0064ff;
  }
  ol {
    padding-left: 25px;
  }
  ol li::marker {
    color: #0064ff;
  }
  a {
    color: #0064ff;
    cursor: pointer;
    position: relative;

    &:hover {
      border-bottom: 2px solid #0064ff;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #3d251414;
    border-top: 2px dashed #cccccc;
    cursor: pointer;
    margin: 10px 0;

    &.ProseMirror-selectednode {
      border-top: 1px solid #e3eeff;
    }
  }
  mark {
    // background-color: #faf594;
    border-radius: 0.4em;
    box-decoration-break: clone;
    padding: 0.1em 0.3em;
  }
  // 引用样式
  blockquote {
    margin: 0;
    border-left: 3px solid #e5e7eb;
    // margin: 1.5rem 0;
    padding-left: 10px;
    color: rgb(135, 136, 138);
    p {
      margin-top: 2.5px;
      margin-bottom: 2.5px;
    }
  }
  pre {
    background: black;
    border-radius: 5px;
    font-family: "Consolas, Monaco", "Andale Mono", "Ubuntu Mono", monospace;
    margin: 10px 0;
    color: white;
    padding: 20px;
    font-size: 14px;

    code {
      background: none;
      color: inherit;
      // font-size: 0.8rem;
      padding: 0;
    }
    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;

    tr{
      height: 25px;
    }

    td,
    th {
      border: 1px solid #dfe1e5;
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin: 0 !important;
      }
    }

    th {
      // background-color: gray;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgb(160 198 255 / 60%);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #0064ff;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  mark {
    border-radius: 0;
    padding: 0;
  }

  .tableWrapper {
    margin: 1.5em 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
.no-hidden-dialog {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
  .el-dialog {
    overflow: visible;
  }
}

.document-wrap {
  & > div {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-right: 200px !important;
      /*no*/
    }
  }
}
ins {
  opacity: 1;
  background: rgba(42, 130, 228, 0.13);
  text-decoration: none;
  padding-top: 5px; /* Adjust these values as needed */
  padding-bottom: 5px;
  box-decoration-break: clone;
}

del {
  opacity: 1;
  background: rgba(128, 128, 128, 0.3);
  padding-top: 5px; /* Adjust these values as needed */
  padding-bottom: 5px;
  box-decoration-break: clone;
}
.hidden {
  visibility: hidden;
}
</style>
<style>
.position_dom {
  background-color: rgb(255, 195, 0, 0.2);
}
</style>
